<template>
  <div class="main main-promo-live">

    <promo-head/>

    <promo-side-bar-left />
    <promo-side-bar-right />

    <promoLive></promoLive>

    <contact-info-inviter></contact-info-inviter>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import promoHead from './components/promoPage/head';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right';
import promoLive from '@/_modules/promo/components/promo-live/promo-live.vue';
import ContactInfoInviter from '@/_modules/promo/components/contact-info-inviter/contact-info-inviter';

export default {
  name: 'promo-page-live',
  components: {
    PromoSideBarLeft,
    PromoSideBarRight,
    promoHead,
    ContactInfoInviter,
    promoLive,
  },
  computed: {
    ...mapGetters('eventStore', ['getEventInfo', 'eventInfo']),
    ...mapState({
      eventInfo: state => state.eventStore.eventInfo,
      contactInfo: state => state.contactStore.contact,
      noteListAll: state => state.noteStore.noteList,
      isPromoPageListLoading: state => state.promoStore.promoPageListLoading,
      promoPageList: state => state.promoStore.promoPageList,
    }),
  },
  mounted() {
    this.callContactInfo();
    this.checkAccess();
  },
  methods: {
    async callContactInfo() {
      if (this.contactInfo && this.contactInfo.id) {
        return;
      }
      await this.$store.dispatch("contactStore/callContact", this.$route.params.eventId);
    },

    async callEventInfo() {
      await this.$store.dispatch('eventStore/event', this.$route.params.eventId);
    },

    callPromoPageList() {
      this.$store.dispatch('promoStore/promoPageListAll', {event_id: this.$route.params.eventId});
    },

    async checkAccess() {
      await this.callEventInfo();
      if (this.eventInfo.personal.has_access !== true) {
        this.$store.dispatch('eventStore/showPromoCheckAccessPopup');
        this.$store.commit('promoStore/promoPageError', '');
        this.$router.push({
          name: 'event-info',
          params: {
            eventId: this.$route.params.eventId
          }
        })
      }
    },
  }
};
</script>
