import { render, staticRenderFns } from "./promo-live.html?vue&type=template&id=0ccdc152&scoped=true&"
import script from "./promo-live.vue?vue&type=script&lang=ts&"
export * from "./promo-live.vue?vue&type=script&lang=ts&"
import style0 from "../../../promo-cabinet/components/cabinet/cabinet.scss?vue&type=style&index=0&id=0ccdc152&scoped=true&lang=scss&"
import style1 from "../../../promo-cabinet/components/cabinet-lobby/cabinet-lobby.scss?vue&type=style&index=1&id=0ccdc152&scoped=true&lang=scss&"
import style2 from "./promo-live.scss?vue&type=style&index=2&id=0ccdc152&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ccdc152",
  null
  
)

export default component.exports