




import Vue from 'vue';
import notesList from '@/views/components/promoPage/notes/notesList.vue';
// import uploader from '@/_modules/promo/components/uploader/uploader.vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TContact } from '@/_types/contact.type';
import { TLivePage } from '@/_types/promo-page/live-page.type';
import PromoLiveBroadcasts from '@/_modules/promo/components/promo-live-broadcasts/promo-live-broadcasts.vue';
// import { TLivePageAgendaItem } from '@/_modules/promo/api/promo-page.api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LiveMediaBlock from '@/_modules/promo/components/live-media-block/live-media-block.vue';
import _cloneDeep from 'lodash.clonedeep';
import ValidationHelper from '@/_helpers/validation.helper';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

type TAbstractObject = {
  [key: string]: any;
}

type TStandTexts = {
  [key: string]: TranslateResult;
}

interface IPromoLiveData {
  agendaScrollHeight: string;
  noteList: TAbstractObject[];
  noteSlides: TAbstractObject[];
  activeSponsorSlideNumber: number;
  activeNotesSlideNumber: number;
  notesQuantityInSlide: number;
  videoPreviewIntroPosterClicked: boolean;
  videoPreviewAgendaPosterClicked: boolean;
  isIntroMediaFullscreen: boolean;
  isAgendaMediaFullscreen: boolean;
}

interface IPromoLiveMethods {
  getNotes: () => void;
  getLivePage: (eventId: number) => Promise<TLivePage>;
  handleSponsorSlideChange: (index: number) => void;
  handleNotesSlideChange: (index: number) => void;
  isPlayerCodeValid: (htmlString: string) => boolean;
  isImageExtension: (url: string) => boolean;
  isValidMediaFile: (url: string) => boolean;
  setAgendaScrollHeight: () => void;
  seekTo: (targetRefName: string) => void;
  setSponsorSlide: (direction: string) => void;
  setNotesSlide: (direction: string) => void;
  setVideoPosterClicked: (targetRefName: string) => void;
  callPromoPageList: () => void;
  toggleMediaBlockFullscreen: (blockName: string) => void;
}

interface IPromoLiveComputed {
  activeSponsorSlide: number;
  activeNotesSlide: number;
  event: TEvent;
  contact: TContact;
  promoPage: TPromoPage;
  liveTitle: string;
  isLivePageLoading: boolean;
  livePageData: TLivePage;
  noteListAll: TAbstractObject;
  isPromoPageListLoading: boolean;
  promoPageList: any[]; // TODO: types, refactor using new promopage store
  hasIntroMediaContent: boolean;
  hasAgendaMediaContent: boolean;
  standTexts: TStandTexts;
  hasFullscreenMedia: boolean;
}

const PromoLive = Vue.extend<IPromoLiveData, IPromoLiveMethods, IPromoLiveComputed>({
  name: 'promo-live',
  components: {
    notesList,
    PromoLiveBroadcasts,
    LiveMediaBlock
  },
  computed: {

    ...mapState({
      noteListAll: state => (state as any).noteStore.noteList,
      isPromoPageListLoading: state => (state as any).promoStore.promoPageListLoading,
      promoPageList: state => (state as any).promoStore.promoPageList,
    }),

    ...mapGetters('_eventStore', {
      event: 'event',
    }),

    ...mapGetters('cabinetLobbyStore', {
      isLivePageLoading: 'isLivePageLoading',
      livePageData: 'livePageData',
    }),

    ...mapGetters('promoPageStore', {
      contact: 'contact',
      promoPage: 'promoPage',
    }),

    liveTitle: {
      get(): string {
        return this.livePageData.title || (this.event ? this.event.title : '');
      },
    },

    hasIntroMediaContent: {
      get(): boolean {
        return this.isValidMediaFile(this.livePageData.video_file_url)
          || this.isPlayerCodeValid(this.livePageData.video_player_frame);
      },
    },

    hasAgendaMediaContent: {
      get(): boolean {
        return this.isValidMediaFile(this.livePageData.agenda_video_file_url)
          || this.isPlayerCodeValid(this.livePageData.agenda_video_player_frame);
      }
    },

    activeSponsorSlide: {
      get(): number {
        return this.activeSponsorSlideNumber;
      },
      set(value: number): void {
        this.activeSponsorSlideNumber = value;
      }
    },

    activeNotesSlide: {
      get(): number {
        return this.activeNotesSlideNumber;
      },
      set(value: number): void {
        this.activeNotesSlideNumber = value;
      }
    },

    standTexts: {
      get(): TStandTexts {
        return {
          inactive: this.$t('organizerCabinet.sections.lobby.standBroadcastInactive'),
          checking: this.$t('organizerCabinet.sections.lobby.standBroadcastChecking'),
        };
      },
    },

    hasFullscreenMedia: {
      get(): boolean {
        return this.isIntroMediaFullscreen || this.isAgendaMediaFullscreen;
      },
    },

  },
  watch: {
    event: {
      handler(): void {
        if (this.event && this.event.id) {
          this.getLivePage(this.event.id);
          this.getNotes();
        }
      }
    },
    livePageData: {
      deep: true,
      handler(): void {
        this.$nextTick(() => {
          this.setAgendaScrollHeight();
        });
      }
    }
  },
  created() {
    this.callPromoPageList();
  },
  mounted() {
    /* Handlers were not enough. */
    if (this.event && this.event.id) {
      this.getLivePage(this.event.id);
      this.getNotes();
    }

    this.$nextTick(() => {
      this.setAgendaScrollHeight();
    });

    window.addEventListener('resize', this.setAgendaScrollHeight);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setAgendaScrollHeight);
  },
  data(): IPromoLiveData {
    return {
      agendaScrollHeight: '350px',
      noteList: [],
      noteSlides: [], // this will be an array of dummy items for slides v-for. Array gets filled in getNotes()
      activeSponsorSlideNumber: 0,
      activeNotesSlideNumber: 0,
      notesQuantityInSlide: 3, // How many notes should be there in one slide
      videoPreviewIntroPosterClicked: false,
      videoPreviewAgendaPosterClicked: false,
      isIntroMediaFullscreen: false,
      isAgendaMediaFullscreen: false,
    };
  },
  methods: {

    ...mapActions('cabinetLobbyStore', {
      getLivePage: 'getLivePage',
    }),

    /* Validate frame code before output
     */
    isPlayerCodeValid(htmlString: string): boolean {
      return (htmlString !== '') && ValidationHelper.isValidVideoStreamEmbed(htmlString);
    },

    /* Gets the list of event creator's notes
     * for the current event
     */
    getNotes(): void {
      this.$store.dispatch('noteStore/callNoteListAll', {
        event_id: this.event.id,
        user_id: this.event.creator_user_id
      }).then(() => {
        this.noteList = _cloneDeep(this.noteListAll.List);

        // Filling in the array of dummy slides
        if (this.noteList && this.noteList.length) {
          let amountOfSlides = 1; // Let there be at least one slide

          // If it makes sense, lets calculate the amount of slides
          if (this.noteList.length > this.notesQuantityInSlide) {
            amountOfSlides = Math.ceil(this.noteList.length / this.notesQuantityInSlide);
          }

          // Using the amount of slides to create the array of dummy slides
          const result: any[] = [];
          for (let i = 0; i < amountOfSlides; i++) {
            result.push(_cloneDeep(this.noteList.slice(i * this.notesQuantityInSlide, i * this.notesQuantityInSlide + this.notesQuantityInSlide)));
          }

          // Outputting the result into the data property
          this.noteSlides = _cloneDeep(result);
        }

      });
    },

    /* Naive: is it an image?
     */
    isImageExtension(url: string): boolean {
      if (!url) return false;
      const whiteList = {
        jpg: true,
        png: true,
        gif: true,
        jpe: true,
        jpeg: true,
        svg: true
      };

      const urlParts: string[] = url.split('.');
      const ext = urlParts.pop().toLowerCase();
      return Object.prototype.hasOwnProperty.call(whiteList, ext);

    },

    /* Naive: is it an image or a video?
     */
    isValidMediaFile(url: string): boolean {
      if (!url) return false;
      const whiteList = {
        mp4: true,
        mpg: true,
        mpeg: true,
      };

      const urlParts: string[] = url.split('.');
      const ext = urlParts.pop().toLowerCase();
      return this.isImageExtension(url) || Object.prototype.hasOwnProperty.call(whiteList, ext);

    },

    /* Perfect scrollbar needs some height to be set
     */
    setAgendaScrollHeight(): void {
      let result = this.agendaScrollHeight; // default is taken from data
      const agendaPhotoArea = this.$refs.agendaPhotoArea;

      const heightPercentageOfWidth = 52.5; // Proportion is taken from design

      if (!agendaPhotoArea) {
        this.agendaScrollHeight = result;
        return;
      }

      let computedWidth: number = (agendaPhotoArea as HTMLElement).getBoundingClientRect().width;
      computedWidth = computedWidth <= 0 ? 0 : Math.floor(computedWidth) / 100 * heightPercentageOfWidth;

      if (computedWidth > 0) {
        result = computedWidth.toString() + 'px';
      }

      this.agendaScrollHeight = result;
    },

    /* For video previews, try seek to around PERCENTAGE% of video duration
     * in hopes that the frame at PERCENTAGE% is not solid color
     * Solid color previews look like there is nothing.
     * First frame of a video is often solid black or other color.
     */
    seekTo(targetRefName: string): void {
      const PERCENTAGE = 0.25;

      if (this.$refs[targetRefName] && PERCENTAGE) {
        try {
          const vid = (this.$refs[targetRefName] as HTMLVideoElement);
          vid.currentTime = vid.duration * PERCENTAGE;
        } catch (e) {
        }
      }

    },

    setSponsorSlide(direction: string): void {
      const dir: number = direction === 'prev' ? -1 : 1;
      let newIndex: number = this.activeSponsorSlide + dir;
      newIndex = newIndex < 0 ? this.livePageData.photos.length - 1 : newIndex;
      newIndex = newIndex > this.livePageData.photos.length - 1 ? 0 : newIndex;

      this.activeSponsorSlide = newIndex;
    },

    setNotesSlide(direction: string): void {
      const dir: number = direction === 'prev' ? -1 : 1;
      let newIndex: number = this.activeNotesSlide + dir;
      newIndex = newIndex < 0 ? this.noteSlides.length - 1 : newIndex;
      newIndex = newIndex > this.noteSlides.length - 1 ? 0 : newIndex;

      this.activeNotesSlide = newIndex;
    },

    handleSponsorSlideChange(index: number): void {
      this.activeSponsorSlide = index;
    },

    handleNotesSlideChange(index: number): void {
      this.activeNotesSlide = index;
    },

    setVideoPosterClicked(targetRefName: string): void {
      switch (targetRefName) {
        case 'videoPreviewIntro':
          this.videoPreviewIntroPosterClicked = true;
          this.$nextTick(() => {
            (this.$refs.videoPreviewIntro as HTMLVideoElement).currentTime = 0;
            (this.$refs.videoPreviewIntro as HTMLVideoElement).play();
          });
          break;
        case 'videoPreviewAgenda':
          this.videoPreviewAgendaPosterClicked = true;
          this.$nextTick(() => {
            (this.$refs.videoPreviewAgenda as HTMLVideoElement).currentTime = 0;
            (this.$refs.videoPreviewAgenda as HTMLVideoElement).play();
          });
          break;
        default:
      }
    },

    callPromoPageList(): void {
      this.$store.dispatch('promoStore/promoPageListAll', { event_id: this.$route.params.eventId });
    },

    toggleMediaBlockFullscreen(blockName: string): void {
      if(blockName === 'intro') {
        this.isIntroMediaFullscreen = !this.isIntroMediaFullscreen;
      } else if (blockName === 'agenda') {
        this.isAgendaMediaFullscreen = !this.isAgendaMediaFullscreen;
      }
    },

  }
});
export default PromoLive;

