<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="Group_186" data-name="Group 186" transform="translate(-588 -160)">
      <g id="Icon_feather-minimize-2" data-name="Icon feather-minimize-2" transform="translate(590.5 162.5)">
        <g class="translate1" >
          <path class="cls-1"  d="m13.6,6.2395l2.1,0l0,2.1" data-name="Path 294"  stroke-linecap="round" stroke-linejoin="round"/>
          <path class="cls-1"  d="m8.3395,15.7l-2.1,0l0,-2.1" data-name="Path 295" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <g class="translate2" >
          <path class="cls-1"  d="m23.5,9.448l2.448,-2.448" data-name="Path 296" stroke-linecap="round" stroke-linejoin="round" transform="translate(-10.948 0)"/>
          <path class="cls-1"  d="m7,25.948l2.448,-2.448" data-name="Path 297"  stroke-linecap="round" stroke-linejoin="round" transform="translate(0 -10.948)"/>
        </g>
      </g>
      <g id="Ellipse_42" data-name="Ellipse 42" class="cls-2" transform="translate(588 160)" >
        <circle class="cls-3" cx="11" cy="11" r="11"/>
        <circle class="cls-4" cx="11" cy="11" r="10.5"/>
      </g>
    </g>
  </svg>

</template>

<script>
import Vue from 'vue';

const IconZoomIn = Vue.extend({
  name: 'icon-zoom-in',
});

export default IconZoomIn;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1,
  .cls-2,
  .cls-4 {
    fill: none;

  }

  .cls-1,
  .cls-2 {
    stroke: #000;
  }

  .cls-1 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-3 {
    stroke: none;
  }

  .translate1 {
    transform: translate(-2px, -2px);
  }

  .translate2 {
    transform: translate(-2px, -2px);
  }
</style>
